@import "./../../../../assets/styles/helpers";
@import './../../../../assets/styles/mixins';

.input_group_wrapper {
  display: flex;
  justify-content: space-between;
  @include font (500, start, Montserrat-Medium, 0.875rem, 1.0625rem);

  &>div {
    width: 49%;
  }
}

.input_text_wrapper {
  &>label {
    display: block;
    margin: 1.5rem 0 .8rem;
    @include font (600, start, Montserrat-SemiBold, 1rem, 1.25rem);
  }

  &>input, &>textarea {
    width: 100%;
    // max-width: 524px;
    height: 51px;
    color: $teamManagement_modal_window_form_text_color;
    border: 0.5px solid #D3D1D1;
    border-radius: 5px;
    @include font (500, start, Montserrat-Medium, 0.875rem, 1.0625rem);
  }

  &>textarea {
    height: auto;
    padding: 5px;
    min-height: 50px;
    resize: none;
  }

}

.error_message {
  margin-top: 10px;
  color: red;
  @include font (600, start, Montserrat-SemiBold, 1rem, 1.25rem);
}

.button_wrapper {
  width: 100%;
  max-width: 150px;
  margin: 2.375rem auto 0;

  &>button {
    margin: 0 auto;
  }
}

@media screen and (max-width: 500px) {
  .input_text_wrapper {
    margin-top: 10px;
  }
}