@import './../../../../assets/styles/helpers';
@import './../../../../assets/styles/mixins';

.constructor_wrapper {
  margin-top: 2rem;
}

.input_wrapper {
  width: 100%;
  max-width: 300px;
  min-width: 150px;
  @include font (500, start, Montserrat-Medium, 0.875rem, 1.0625rem);
  margin-bottom: 30px;

  label {
    display: block;
    margin-bottom: 10px;
    @include font (500, start, Montserrat-Medium, 1rem, 1.0625rem);
  }

  input,
  textarea {
    height: 51px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    @include font (500, start, Montserrat-Medium, 0.875rem, 1.0625rem);
  }

  textarea {
    width: 100%;
    min-height: 51px;
    padding: 5px;
    resize: none;
  }
}

.inputs_group_wrapper {
  width: 100%;
  display: flex;

  &>div:first-child {
    margin-right: 2rem;
  }

  &>div>input[type=radio] {
    height: 20px;
  }


}

.button_wrapper {
  width: 150px;
  margin: 0 auto;
}

.error_message {
  color: red;
  @include font (600, start, Montserrat-SemiBold, 1rem, 1.25rem);
}