@import './../../../../assets/styles/mixins';
@import './../../../../assets/styles/helpers';

.section {
  display: flex;
  //padding: 15px;
  padding: 0.9375rem;
  cursor: pointer;

  &:last-child {
    //margin: 20px 15px;
    margin: 1.25rem 0.9375rem;
  }

  &:hover {
    background-color: #DCEEFF;
  }

  &_name {
    //margin-left: 40px;
    margin-left: 2.5rem;
    @include font(400, start, Montserrat-Regular, 1rem, 1.25rem);
  }
}