@import './../../../../../assets/styles/helpers';
@import './../../../../../assets/styles/mixins';

.block_with_textarea {
  display: flex;
  align-items: center;
  margin: 1rem 0 6px;
}

.block_with_checkbox {

  &_input {
    position: absolute;
    z-index: -1;
    //opacity: 0;
  }

  &_input + label::before {
    position: absolute;
    top: 0;
    left: 7px;
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    //flex-shrink: 0;
    //flex-grow: 0;
    border: 1px solid $motivatin_blue;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 80%;
    cursor: pointer;
  }

  &_input:checked + label::before {
    cursor: pointer;
    background-color: $motivatin_blue;
    background-image: url('../../../../../assets/images/coachingCorner/checked.png');
  }

  &_label {
    position: relative;
    display: block;
    padding-left: 1.875rem;
    cursor: pointer;
    color: #848484;
    @include font (600, start, Montserrat-Regular, 0.625rem, 0.75rem);
  }

}

.block_with_textarea textarea {
  width: 100%;
  max-width: 414px;
  padding: 5px;
  height: 28px;
  resize: none;
  overflow-y: auto;
  color: $textarea_color;
  border: 0.2px solid #B7B7B7;
  margin-right: 1.063rem;
  @include font (500, start, Montserrat-Regular, 0.625rem, 0.75rem);
}

.send_button {
  @include font(700, center, Montserrat-Regular, 0.75rem, 0.938rem);
  //color: #2697fe;
  cursor: pointer;
}

.btn_span {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 219, 113, .8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  display: none;
  @include font (400, center, Montserrat-Regular, 0.875rem, 1rem);
}