@import './../../../assets/styles/helpers';
@import './../../../assets/styles/mixins';

.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.5625rem 2.8125rem;
  border-bottom: 1px solid $header_border_color;

  &_mode_wrapper {
    position: absolute;
    right: 0;
    bottom: 100%;
    width: auto;
    @include font (400, start, Montserrat-Regular, 1rem, 1.25rem);
  }

  &_buttons {
    position: relative;
    display: flex;
    justify-content: flex-end;

    &>div:nth-child(1) {
      @include font (400, center, Montserrat-Regular, 1.125rem, 1.375rem);
    }

    &_wrapper {
      display: flex;

      &>div:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  @include mobile-min {
    padding: 2rem 1rem;
  }
}

.header_buttons .role {
  position: absolute;
  right: 0;
  top: 115%;
  color: $switch_account_blue;
  @include font (400, center, Montserrat-Regular, .7rem, .7rem);
}
