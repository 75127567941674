@import "./../../../assets/styles/mixins";
@import './../../../assets/styles/helpers';

.button_with_image {
  width: 100%;
  //min-width: 183px;
  display: flex;
 // height: 45px;
  align-items: center;
  justify-content: center;
 // padding: 0 18px;
  background-color: $button_with_image;
  //width: 120px;
  //height: 40px;
  border-radius: 5px;
  color: $white;
  border: none;
 // min-width: 150px;
  //margin: 0 20px;
  cursor: pointer;
  @include font (700, center, Montserrat-Bold, 1rem, 1.25rem);

  &_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 21px;
    height: 21px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }

  &_value {
    text-align: start;
    margin-left: 10px;
  }
}


.disabled_button {
  opacity: .5;
}