@import './../../../../assets/styles/mixins';
@import './../../../../assets/styles/helpers';

.select_border_team_selection {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.team_selection {
  height: 64px;

  &_block {
    position: relative;
    width: 100%;
    max-width: 388px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    @include font (700, center, Montserrat-Bold, 1rem, 1.25rem);

    &_dropdown_name {
      position: absolute;
      top: 5px;
      left: 5px;
      @include font (600, center, Montserrat-Regular, .77rem, 1rem);
    }

    & > div:first-child {
      margin-right: 10px;
    }

    & > ul {
      position: absolute;
      width: 100%;
      top: 70px;
      background-color: white;
      z-index: 2;
      padding: 15px;

      li {
        @include font (400, start, Montserrat-Medium, 1rem, 1.25rem);
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 70px;
        // text-align: start;
        padding: 10px;
       // box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
        margin: 0 0 15px 0;
      }
    }
  }
}

.message_counter {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #0B8AFF;
  color: $white;
  @include font (700, center, Montserrat-Medium, 1.125rem, 1.875rem);
}