@import '../../../../assets/styles/helpers';
@import '../../../../assets/styles/mixins';

.modal_window {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(163, 163, 163, .5);
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  &_info_block {
    position: relative;
    width: 100%;
    max-width: 700px;
   // padding: 40px;
    background-color: $white;
    @include font (600, start, Montserrat-Regular, 1rem, 1.25rem);

    &_text {
     // padding: 20px 50px 50px;
      padding: 1.25rem 3.125rem 3.125rem;
    }
  }
}

.modal_window_header {
  position: relative;
  border-bottom: 2px solid #d7d6d6;
  padding: 15px;
  display: flex;
  align-items: center;

  &>div:nth-child(2) {
    @include font (600, start, Montserrat-Regular, 1.25rem, 1.5rem);
    margin-left: 15px;
  }


  &_logo {
    width: 50px;
    height: 50px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }
}

.modal_window_link {
  padding: 10px;
  @include font (300, end, Montserrat-Regular, 1rem, 1.5rem);

  span {
   // text-decoration: underline;
    color: #1872ec;
    cursor: pointer;
  }
}

.cancel_button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  overflow: hidden;

  & img {
    display: block;
    width: 100%;
  }
}