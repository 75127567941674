@import "./../../../assets/styles/mixins";
@import './../../../assets/styles/helpers';

.error_container {
  animation-name: showContainer;
  animation-duration: .1s;
  display: block;
  position: relative;
  margin-bottom: 10px;
  @include font(400, center, Montserrat-Regular, 1rem, 1.75rem);

  .error_content {
    text-align: center;
    padding: 5px;
    color: $red;
    background: $lightest-red;
    border-radius: 4px;

    .close {
      float: right;
      @include font(600, center, Montserrat-Bold, 1.75rem, 1rem);
      padding: 0;
      background-color: transparent;
      border: 0;
      color: $red;
      cursor: pointer;
      outline: none;
      opacity: 0.5;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .success {
    color: $green;
    background: $lightest-green;

    .close {
      color: $green;
    }
  }
}

@keyframes showContainer {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}