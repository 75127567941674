.error_container {
  display: block;
  //position: relative;
  margin-bottom: 10px;
  //position: fixed;
  //top: 0;
  //right: 0;

  .error_content {
    z-index: 1;
    animation-name: showContainer;
    animation-duration: 2s;
    animation-delay: 5s;
    //position: absolute;
    width: 100%;
    top: -11px;
    text-align: center;
    padding: 5px;
    color: #d00020;
    background: #f67388;
    border-radius: 4px;
    line-height: 1.75rem;
    font-family: "Montserrat-Regular", serif;

    .close {
      float: right;
      font-size: 1.75rem;
      font-weight: bold;
      line-height: 1;
      padding: 0;
      background-color: transparent;
      border: 0;
      color: #d00020;
      cursor: pointer;
      outline: none;
      opacity: 0.5;

      &:hover {
        font-weight: bold;
        opacity: 0.8;
      }
    }
  }

  .success {
    color: #5eb319;
    background: #b7ed8b;

    .close {
      color: #5eb319;
    }
  }

  .text-notification {
    display: inline-block;
    white-space: pre-wrap;
  }
}
