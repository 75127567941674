@import './../../../../../assets/styles/helpers';
@import './../../../../../assets/styles/mixins';

.create_message_item {
  margin-bottom: 20px;
  border-radius: 5px;
  @include font (700, start, Montserrat-Regular, 1rem, 1.25rem);

  textarea {
    width: 100%;
   // height: 77px;
   // padding: 25px 34px 25px 0px;
    //padding-top: 15px;
    resize: none;
    //overflow-y: auto;
    color: $textarea_color;
   // border-radius: 10px;
    border: none;
    margin-right: 2.25rem;
    @include font (500, start, Montserrat-Regular, 	1.25rem, 1.5rem);
  }
}

.block_with_textarea {
  //padding: 20px 50px 18px 40px;
 // padding: 10px 30px 10px 30px;
  padding: 0.625rem 1.875rem  0.625rem 1.875rem;
  margin-left: 40px;
  display: flex;
  align-items: center;
  border: 0.5px solid #BDBABA;
  border-radius: 130px;
  background-color: $white;


  & > div:first-child {
    flex-shrink: 0;
    margin-right: 1.5rem;
  }

  //& > div:nth-child(2) {
  //  width: 100%;
  //}

}

.block_with_checkbox {
  display: flex;
  justify-content: space-between;
  margin-left: 4.6875rem;
  margin-top: 1.1875rem;

  div {
    display: flex;
    align-items: center;
    margin-left: 1.25rem;
    width: 200px;
    @include font (500, start, Montserrat-Regular, 1rem, 1.25rem);
  }

  //&_input {
  //  display: block;
  //  width: 20px;
  //  height: 20px;
  //}

  &_input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &_input + label::before {
    position: absolute;
    top: -3px;
    left: -10px;
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    //flex-shrink: 0;
    //flex-grow: 0;
    border: 1px solid $motivatin_blue;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 80%;
    cursor: pointer;
  }

  &_input:checked + label::before {
    cursor: pointer;
    background-color: $motivatin_blue;
    background-image: url('../../../../../assets/images/coachingCorner/checked.png');
  }

  &_label {
    position: relative;
    display: block;
    padding-left: 1.875rem;
    cursor: pointer;
  }

}

.ava {
  width: 77px;
  height: 77px;
  overflow: hidden;
  border-radius: 50%;

  & img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .block_with_textarea {
    margin-left: 0;
    padding:2px 5px;
  }

  .ava {
    width: 40px;
    height: 40px;
  }
}