//здесь будут общие стили для всего проекта (без модуля)
@import "./../../assets/styles/reset";
@import "./../../assets/fonts/fonts";
@import '../../assets/styles/helpers';
@import '../../assets/styles/mixins';

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: white;
  -webkit-overflow-scrolling: touch;
}

//для кнопки уведомлений
.notifications_button {
  position: relative;
}

.notification_alert {
  position: fixed;
  max-width: 300px;
  width: 100%;
  z-index: 99999999999;
  top: 0;
  left: 0;
}

* {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
  @include adaptiv_value('font-size', 16, 14);
}

.cursor {
  cursor: pointer
}

//блок ценТровщик
.cent {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}

.app {
  width: 100%;
  min-height: 100vh;
}

.common_wrapper {
  position: relative;
  width: 100%;
  display: flex;
}

.menu_block {
  //width: 300px;
  width: 30%;
  max-width: 18.75rem;
  min-height: 100vh;
  flex-shrink: 0;
}

//класс для показа или скрывания сайдбара
//.sidebar_hide {
//  display: none;
//}



///////
.loader-container {
  margin-top: 100px;
}

h1 {
  @include font(600, start, Montserrat-Regular, 1.25rem, 1.5rem)
}

.overflow-hidden {
  overflow: hidden;
}

//для бургера
.menu_button {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  cursor: pointer;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
  }

}

//для таймпикера
.timeSelector {
  display: block;
  width: 100%;
  padding: 3.75px 7.5px;
}

//это для модалки перед create team
.hovered {
  background-color: #0061ff33;
}

.bg_teams {
  background-color: #f8dd88;
}

a {
  text-decoration: none;
  color: black;
}

input {
  border: none;
  outline: none;
  padding: 10px;
  @include font(500, start, Montserrat-Regular, 1rem, 1.25rem);
}

//для кнопки, которую нельзя сделать общей компонентой
.button_with_image {
  width: 100%;
  max-width: 183px;
  min-width: 100px;
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
  background-color: $button_with_image;
  border-radius: 5px;
  color: $white;
  border: none;
  cursor: pointer;
  @include font (700, center, Montserrat-Bold, 1rem, 1.25rem);

  &_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
   // width: 21px;
    @include adaptiv_value('width', 21, 12);
   // height: 21px;
    @include adaptiv_value('width', 21, 12);
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }

  &_value {
    text-align: start;
    margin-left: 10px;
  }
}

.button_without_image {
  width: 100%;
  max-width: 261px;
  height: 46px;
  align-items: center;
  background-color: $button_with_image;
  border-radius: 5px;
  color: $white;
  border: none;
  cursor: pointer;
  @include font(700, center, Montserrat-Bold, 1rem, 1.25rem);
}

.btn {
  position: relative;
}

.btn span {
  position: absolute;
  top: -50px;
  left: 30%;
  background: #141515;
  color: $white;
  padding: 5px;
  border-radius: 6px;
  display: none;
  @include font(400, center, Montserrat-Regular, 0.875rem, 1rem);
}

.disabled_button {
  opacity: .5;
}

//это border часто используемый
.border {
  box-shadow: 0px -1px 28px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
}

.border_1 {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.select_border_team_selection {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.select_border {
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.digital_pulse_filters_border {
  border: 0.5px solid #BDBABA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.select_with_image_border {
  box-shadow: 0px -1px 28px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
}

//для добавления дополнительного позиционирования
.add_position {
  position: relative;
}

.body_block {
 // width: calc(100% - 18.75rem);
  width: 70%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
}

//блок где будет select команд
.team_selection {
  height: 64px;

  &_block {
    position: relative;
    max-width: 388px;
    //width: 388px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    @include font(700, center, Montserrat-Bold, 1rem, 1.25rem);

    &_dropdown_name {
      position: absolute;
      top: 5px;
      left: 5px;
      @include font(600, center, Montserrat-Regular, .77rem, 1rem);
    }

    & > div:first-child {
      margin-right: 10px;
    }

    & > ul {
      position: absolute;
      width: 100%;
      top: 80px;
      background-color: white;
      z-index: 2;

      li {
        @include font(400, start, Montserrat-Medium, 1rem, 1.25rem);
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        // text-align: start;
        padding: 15px;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
        margin: 1px 0;
      }
    }
  }
}

//юлок где будут селекты для интервала, команд, департаментов, участников команд на дашборде
.selection_block {
  display: flex;
  align-items: flex-start;
  //margin-bottom: 40px;
  margin-bottom: 2.5rem;

  & > div:nth-child(last-child) {
    margin-bottom: 0;
  }

  &_item_wrapper {
    width: 100%;
    max-width: 412px;
    min-height: 86px;
    margin-right: 22px;
  }

  &_item_wrapper_certain_team_page {
    width: 100%;
    max-width: 543px;
    min-height: 86px;
    margin-right: 22px;
  }
}

//блок где будут табки-переключалки
.tabs_block {
  width: 100%;
  padding: 0.3125rem 0 0 7.1875rem;
  display: flex;
  justify-content: flex-start;
}

//серый блок с отображением страниц
.common_pages_block {
  position: relative;
  width: 100%;
  flex-grow: 1;
  // @include adaptiv_value('padding-top', 90, 10);
  // @include adaptiv_value('padding-right', 70, 10);
  // @include adaptiv_value('padding-bottom', 135, 10);
  // @include adaptiv_value('padding-left', 100, 10);
  background-color: $body;
 // border-radius: 50px;
  border-radius: 50px 50px 0 0;
}

.add_padding {
  padding: 4.375rem 2.5rem 1.625rem 2.5rem;
}

//для страницы teamManagement для каждого подгружаемого участника
.member_header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: baseline;
  flex-wrap: nowrap;
  //align-items: center;
  // padding: 0 10px;
  @include font (400, start, Montserrat-Regular, 1rem, 1.25rem);

  & > div {
    width: 20%;

    &:first-child {
      flex-shrink: 0;
      width: 2.8125rem;
     // word-wrap: break-word;
      // margin-right: 20px;
    }

    &:nth-child(2) {
      width: auto;
      flex-shrink: 0;
      align-self: center;
    }

    &:nth-child(3) {
      width: 10%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(4) {
      width: 10%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(5) {
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(6) {
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(7) {
      width: 20%;
      flex-shrink: 0;
      word-wrap: break-word;
      // align-self: center;
      & > div {
        margin: 0 auto;
      }
    }

    &:last-child {
      width: 25px;
      margin-left: 10px;
      align-self: center;
    }
  }
}

//для страницы teamManagement(report) для каждого подгружаемого участника
.checkinMember_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: baseline;
  flex-wrap: nowrap;
  @include font (600, start, Montserrat-Regular, 1rem, 1.25rem);

  & > div {
    width: 25%;
    //  padding: 10px 0;


    &:nth-child(2) {
      width: 15%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(3) {
      width: 15%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(4) {
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:first-child {
      flex-shrink: 0;
      width: 20px;
      margin-right: 20px;
    }

    &:last-child {
      width: 30%;
    }

  }
}

//для страницы companyManagement для каждого подгружаемого участника
.member_header_cm {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: baseline;
  flex-wrap: nowrap;
  padding: 1px;
  //align-items: center;
  // padding: 0 10px;
  @include font (400, start, Montserrat-Regular, 1rem, 1.25rem);


  & > div {
    width: 20%;
    padding: 1px;

    &:first-child {
      flex-shrink: 0;
      width: 45px;
      // margin-right: 20px;
    }

    //&:nth-child(2) {
    //  width: auto;
    //  flex-shrink: 0;
    //  align-self: center;
    //}

    &:nth-child(2) {
      width: 10%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(3) {
      width: 10%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(4) {
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(5) {
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(6) {
      width: 20%;
      flex-shrink: 0;
      word-wrap: break-word;
      // align-self: center;
      & > div {
        margin: 0 auto;
      }
    }

    &:last-child {
      width: 25px;
      margin-left: 10px;
      align-self: center;
    }
  }
}

//for team leaders dropdown
.leader_header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: baseline;
  flex-wrap: nowrap;
  padding: 1px;
  position: relative;
  @include font(400, start, Montserrat-Regular, 1rem, 1.25rem);

  & > div {
    width: 10%;
    padding: 1px;
    flex-shrink: 0;
    word-wrap: break-word;
    cursor: default;

    &:first-child {
      flex-shrink: 0;
      width: 45px;
    }

    &:nth-child(2) {
      width: 15%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(3) {
      width: 15%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(4) {
      width: 15%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8){
      //width: 30%;
      text-align: center;
      & > div {
        margin: 0 auto;
      }
    }

    &:last-child {
     // text-align: center;
    }
  }
}

//для внешней части пикера с выбором периодов и времени
.period_select, .time_select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $white;
  width: 235px;
  height: 64px;

  & input {
    display: block;
    width: 100%;
    border: none;
    @include font(400, center, Montserrat-Regular, 1rem, 1.25rem);
  }
}

//для страницы DepartmentManagement для каждой подгружаемой команды
.team_header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: baseline;
  flex-wrap: nowrap;
  @include font(400, start, Montserrat-Regular, 1rem, 1.25rem);

  & > div {
    width: 15%;

    &:first-child {
      flex-shrink: 0;
      width: 45px;
    }

    &:nth-child(2) {
      width: 10%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(3) {
      width: 10%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(4) {
      width: 10%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(5) {
      width: 20%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(6) {
      width: 20%;
      flex-shrink: 0;
      word-wrap: break-word;
    }

    &:nth-child(7) {
      width: 15%;
      flex-shrink: 0;
      word-wrap: break-word;
      // align-self: center;
      & > div {
        margin: 0 auto;
      }
    }

    &:last-child {
      width: 25px;
      margin-left: 10px;
      align-self: center;
    }
  }
}

//для внешней части пикера с выбором даты (это селекты с картинками)
.data_select {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: $white;
  @include font (600, start, Montserrat-SemiBold, 1rem, 1.25rem);

  &_name {
    margin: 5px 0 0 10px;
    @include font (500, start, Montserrat-Regular, 1rem, 1.25rem);
  }

  &_main {
    //width: 100%;
    //max-width: 300px;
    margin: 0 auto;
    padding: 10px 0;
  }

  &_image {
    position: absolute;
    right: 0;
    bottom: 0;
    //transform: matrix(0.85, -0.53, -0.53, -0.85, 0, 0);
    transform: rotate(-30.42deg);
  }


  & input {
    display: block;
    width: auto;
    border: none;
   // @include font (600, center, Montserrat-SemiBold, 1.6rem, 2rem);
  }
}

//это для показа сообщения принаведении на кнопку "i" в commonSelect managment
.info_message {
  display: none;
  //width: 50%;
 // padding: 10px 20px;
  padding: 0.625rem 1.25rem;
  position: absolute;
  border-radius: 30px;
  background-color: $error_message;
  @include font (600, start, Montserrat-SemiBold, 1.125rem, 1.375rem)
}

.info_button {
  position: absolute;
  border: 1px solid $button_with_image;
  border-radius: 50%;
}

.info_button:hover + .info_message {
  display: block;
}

//это красный кружочек в coaching cornere
.red_point {
  width: 10px;
  height: 10px;
  background-color: $red;
  border-radius: 50%;
}

//это для блока фильтров в digitalPulse
.filters_block {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 1.875rem;
  margin-bottom: 1rem;
  gap: 1.5rem;

  &.cm_org_diagram {
    gap: 0
  }

  & > div {
    width: 100%;
    max-width: 328px;
    height: 60px;
    min-width: 170px;
  }

  & + .reset_filters_button {
    width: 100px;
    background-color: $active_blue;
    padding: 7px;
    margin: 1.5rem 0 1.5rem auto;
    cursor: pointer;
    border-radius: 7px;
    @include font(300, center, Montserrat-Regular, .8rem, 1.375rem)
  }
}

//это на странице certainResults для bar графика и его лэйблов
//но обязательно спозиционировать как relative общего родителя и задать высоту ему
.bar_chart_wrapper {
  position: absolute;
  top: -30px;
  width: 100%;
}

.bar_chart_labels {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #848484;
  @include font(500, start, Montserrat-Regular, 0.625rem, 0.75rem);

  span {
    &:last-child {
      text-align: end;
    }
  }

  display: flex;
  justify-content: space-between;
  padding: 0 0.625rem 0 1.5625rem;
}

//dashboard
.chart_xaxis_title {
  position: absolute;
  right: 20px;
  bottom: 20px;
  @include font(400, start, Montserrat-Regular, 9px, 11px);
}

//это обертка секции которая включает в себя два графика (в дашборде применяем)
//и все последующие классы для обертки графиков в этой секции
.section_wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4.375rem;
}

.donat_chart_wrapper {
  position: relative;
  flex-shrink: 0;
  width: 365px;
  height: 300px;
  margin-right: 2.8125rem;
  background-color: $white;
}

.line_chart_wrapper{
  box-sizing: border-box;
  position: relative;
  width: 70%;
  height: 300px;
  background-color: $white;
}

.chart_name {
  width: 80%;
  @include font(500, start, Montserrat-Regular, 0.875rem, 1.0625rem);
  padding: 17px 0 0 15px;
}

.chart_info_icon {
  position: absolute;
  top: 15px;
  right: 5px;
  width: 1.5625rem;
  height: 1.5625rem;
  border: 2px solid $link;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.chart_info_icon:hover + .chart_info_text {
  display: block;
}

.chart_info_text {
  width: 80%;
  padding: 10px;
  position: absolute;
  top: 40px;
  right: 50px;
  display: none;
  z-index: 6;
  background-color: $body;
  border-radius: 20px;
  @include font(600, center, Montserrat-Regular, 1.125rem, 1.375rem);
}

.chart_body {
  height: 90%;
  position: relative;
  z-index: 0;
}

//на дашборде есть блок с характеристикой
.characteristics_chart {
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 305px;
 // width: 500px;
  width: 50%;
  max-width: 500px;
 // min-width: 330px;

  & > div {
    @include font(500, center, Montserrat-Regular, 0.875rem, 1.0625rem);
  }

}

.characteristics_description {
  min-height: 200px;
  width: 100%;
 // margin: 0px 20px 0px 30px;
  margin: 0 1.25rem 0 1.875rem;
 // padding: 0.875rem 2.5rem 2.6875rem 2.5rem;
  padding: 0.875rem 1rem 2.6875rem 1rem;

  //padding-top: 14px;
  //@include adaptiv_value('padding-right', 40, 10);
  //@include adaptiv_value('padding-bottom', 43, 10);
  //@include adaptiv_value('padding-left', 40, 10);


  &_body {
    @include font(500, start, Montserrat-Regular, 0.875rem, 1.0625rem);

    &_name {
      @include font (600, center, Montserrat-Regular, 0.875rem, 1.0625rem);
      margin-bottom: 22px;
    }

    ul {
      list-style-type: circle;
      @include font (500, start, Montserrat-Regular, 0.875rem, 1.0625rem);
      margin-top: 12px;
      //@include adaptiv_value('margin-left', 40, 10);
      margin-left: 2.5rem;
    }
  }

}

///ДЛЯ ONBOARDING
.onboarding_caption {
  background-color: $white;
  color: $black;
  padding: 47px 20px;
  margin-bottom: 15px;
  @include font(600, center, Montserrat-Regular, 1.25rem, 1.5rem);
}

.onboarding_section_wrapper {
  display: flex;
  //width: 100%;
  border-radius: 30px;
  background-color: $white;
  border: 1px solid $onboarding_section_border_color;
  //padding: 30px 0 30px 0;
  padding: 1.875rem .5rem;
  margin-bottom: 10px;

  &_question_number {
    // border: 1px solid green;
    width: 100px;
    flex-shrink: 0;
   // padding-left: 30px;
    padding-left: 1.875rem;
    @include font(500, start, Montserrat-Regular, 1rem, 1.25rem);
  }

  &_content {
    // border: 1px solid blue;
    //margin-right: 20px;
    margin-right: 1.25rem;
    width: calc(100% - 100px);

    .onboarding_question {
      // border: 1px solid red;
      margin-bottom: 45px;
      word-wrap: anywhere;
      @include font(500, start, Montserrat-Regular, 1rem, 1.25rem);
    }

    .onboarding_prompt {
      //  border: 1px solid green;
      color: #848484;
      //margin-bottom: 40px;
      margin-bottom: 2.5rem;
      word-wrap: anywhere;
      @include font(500, start, Montserrat-Regular, 1rem, 1.25rem);
    }

    .onboarding_answers_wrapper {
      //  border: 1px solid blue;
      width: 100%;
      max-width: 1060px;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .answer_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    padding: 5px;
    //добавила
    width: 100%;
    height: 50px;
    border: 0.5px solid #D3D1D1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: $white;
    //margin-right: 50px;
    margin-right: 3.125rem;
    @include font(500, center, Montserrat-Regular, 0.875rem, 1.0625rem);
    margin-bottom: 40px;
    cursor: pointer;
  }

  .selected {
    background-color: $onboarding_selected_answer;
    color: $white;
  }
}

.onboarding_buttons_position {
  display: flex;
  justify-content: flex-end;
  //margin: 40px 0;
  margin: 2rem 0;
}

.best_work_page_buttons {

  & div:last-child {
    margin-left: 3.75rem;
  }
}

///////////////INVITATION EMAILS


///////////////////////////////////////////








/////////////////


////////////////////////////////////////////////////////////////////////////
// REACTTOUR

#___reactour {

  .reactour__helper {
    max-width: 270px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: $white;
    @include font(400, start, Montserrat-Regular, 0.875rem, 1.375rem);
    @include laptop-min {
      max-width: 750px;
    }
    @include mobile {
      max-width: 450px;
    }
    @include mobile-min {
      //max-width: 360px;
     // max-width: 100%;
      width: 100%;
    }

    &--is-open {
      padding: 0;
    }

    .reactour__close {
      svg {
        display: none;
      }

      &::after {
        content: "";
        position: absolute;
        width: 21px;
        height: 21px;
        //background: #f5f5f5;
        //border-radius: 50%;
        //border-color: #e7e7e7;
        right: -16px;
        top: -16px;
        background-image: url("../../assets/images/closeTour.png");
        background-size: 10px 10px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }
  }



  [data-tour-elem="controls"] {
    justify-content: center;
    border: none;

    [data-tour-elem="navigation"] {
      //position: absolute;
      //bottom: 0;
      height: 52px;
      background: #EBEBEB;
      width: 100%;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      //background: transparent;

      .reactour__dot {
        width: 12px;
        height: 12px;
        margin: 3px;

        &--is-active {
          color: #69B5F6;
          background: #69B5F6;
        }
      }
    }
    [data-tour-elem="left-arrow"],
    [data-tour-elem="right-arrow"] {
      margin: 0;
      height: 52px;
      width: 75px;
      background: #2697FE !important;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;
      flex-shrink: 0;
      @include font (600, center, Montserrat-Regular, 0.875rem, 1.063rem);
    }

    [data-tour-elem="left-arrow"] {
      //position: absolute;
      //left: 0;
      //bottom: 0;
      border-bottom-left-radius: 5px;
    }

    [data-tour-elem="right-arrow"] {
      //position: absolute;
      //right: 0;
      //bottom: 0;
      border-bottom-right-radius: 5px;
    }
  }
}


.hint_body {
  margin: 20px;
}

/////////////////////////////////////////////////////////////////////////////////////


//.my-flatpickr-calendar {
//  background-color: red;
//}

// reset style for accordion

.MuiAccordion-root:before {
  opacity: 0 !important;
}
//
//@media screen and (max-width: 1260px) {
//  .section_wrapper {
//    flex-direction: column;
//    align-items: center;
//    justify-content: flex-start;
//  }
//
//  .donat_chart_wrapper {
//    flex-shrink: 0;
//    height: 294px;
//    background-color: $white;
//    margin-bottom: 20px;
//  }
//
//  .line_chart_wrapper {
//    width: 90%;
//    padding-bottom: 10px;
//    height: 300px;
//    margin-right: 0px;
//    //min-width: 800px;
//    background-color: $white;
//  }
//}


////////////



@media screen and (max-width: 1350px) {

  .onboarding_section_wrapper .answer_item {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1240px) {
  :root {
    font-size: 14px;
  }

  .section_wrapper {
    display: block;
    margin-bottom: 0;
  }

  .chart_body {
    width: 100%;
  }

  .donat_chart_wrapper,
  .line_chart_wrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .characteristics_chart {
    width: 100%;
    margin: 0 auto;
  }

  .characteristics_description {
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 1000px) {
  .common_pages_block {
    padding: 20px 10px 1.625rem;
  }
}


@media screen and (max-width: 900px) {
  .menu_block {
    //width: 60px;
    //z-index: 100;
  }

  .body_block {
   // width: calc(100% - 60px);
  }

  .selection_block {
    flex-direction: column;

    &_item_wrapper {
      max-width: 100%;
    }

    &_item_wrapper_certain_team_page {
      width: 100%;
      max-width: 543px;
      min-height: 86px;
     // margin-right: 22px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 870px) {
  .onboarding_section_wrapper_content .onboarding_answers_wrapper {
    justify-content: space-between;
  }

  .onboarding_section_wrapper .answer_item  .add_for_time_picker {
    width: 100%;
  }

  .onboarding_section_wrapper .answer_item {
    width: 45%;
    min-width: 150px;
    margin-right: 0px;

    &.add_for_time_picker {
      width: 100%;
    }
  }


}

@media screen and (max-width: 720px) {
  .tabs_block {
    padding: 10px 50px 0;
  }

  .filters_block {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .onboarding_buttons_position {
    &>div {
      margin-left: 20px;
    }
  }

}

@media screen and (max-width: 580px) {
  :root {
    font-size: 12px;
  }

  .menu_button {
    display: block;
  }

  .menu_block {
    //position: fixed;
    //background-color: white;
    //top: 0;
    //left: -100%;
  }

  .body_block {
   // width: 100%;
  }

  //дополнительный марджин для некоторых onboarding_answers_wrapper
  .add_margin_oaw {
    margin-top: -20px;
  }

  .onboarding_section_wrapper.add_recommend_margin {
    margin-top: -50px;
  }

  .onboarding_section_wrapper_content {
    width: calc(100% - 30px);
   // width: 100%;

    .onboarding_prompt {
      margin-bottom: 1rem;
    }
  }

  .onboarding_section_wrapper .answer_item {
    margin-bottom: 1rem;
  }

  .onboarding_section_wrapper_content .onboarding_answers_wrapper .answer_item {
    margin-right: 0;
  }

  .onboarding_question {
    margin-bottom: 1rem;
  }

  .onboarding_section_wrapper_question_number {
    width: 30px;
    padding-left: 10px;
  }

  .tabs_block {
    padding: 0;
    margin-top: 10px;
  }

  //.common_pages_block {
  //  border-radius: 0 0 50px 50px;
  //}
}

@media screen and (max-width: 440px) {
  :root {
    font-size: 11px;
  }

  .onboarding_section_wrapper .answer_item {
    width: 100%;
  }

  .characteristics_chart {
    height: 250px;
  }

  .section_wrapper {
    padding: 2px;
  }

  .member_header_cm>div:last-child {
    margin-left: 4px;
  }


  .best_work_page_buttons {
    margin: 10px;

    &>span:nth-child(2) {
      margin-left: 10px;
    }

    &>button {
      display: block;
     width: 45%;
      max-width: 183px;
    }
  }

  #___reactour {

    .reactour__helper {
      width: 100%;
     // max-width: 260px;
     // min-width: 200px;

      &--is-open {
        padding: 0;
      }

    }

    [data-tour-elem="controls"] {
      [data-tour-elem="navigation"] {
        height: 30px;

        .reactour__dot {
          width: 7px;
          height: 7px;
          margin: 3px;
        }
      }
      [data-tour-elem="left-arrow"],
      [data-tour-elem="right-arrow"] {
        height: 30px;
      }


      [data-tour-elem="right-arrow"] {
        border-bottom-right-radius: 5px;
      }
    }
  }

  .hint_body {
    margin: 10px 20px 0 10px;
  }
}





.sidebar_show {
  left: 0;
}
