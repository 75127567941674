@import "./../../../../assets/styles/helpers";
@import './../../../../assets/styles/mixins';

.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(157, 157, 157, .5);
  z-index: 115;
  overflow-y: auto;
}

.modal_window {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.125rem;
  width: 80%;
  max-width: 750px;
  background-color: $white;
  border-radius: 10px;

  &_header {
    position: relative;
    // padding: 49px 0 43px 43px;
    padding: 3.0625rem 0 2.6875rem 2.6875rem;

    p {
      @include font (600, start, Montserrat-SemiBold, 1.25rem, 1.5rem)
    }
  }

  &_separator {
    width: 100%;
    height: 1px;
    background-color: $teamManagement_modal_window_separator_color;
  }

  &_body {
    padding: 0 46px 42px 46px;

    p:first-child {
      @include font (500, start, Montserrat-Medium, 1.125rem, 1.375rem)

    }
  }
}

//.choice_wrapper {
//  margin-top: 45px;
//  @include font (500, start, Montserrat-Medium, 1.125rem, 1.375rem);
//
//  & input {
//    margin-right: 1.125rem;
//  }
//}

.input_text_wrapper {
  margin-top: 32px;

  &>label {
    display: block;
    margin-bottom: 1.5rem;
    @include font (600, start, Montserrat-SemiBold, 1rem, 1.25rem);
  }

  &>input {
    width: 100%;
    // max-width: 524px;
    height: 51px;
    color: $teamManagement_modal_window_form_text_color;
    border: 0.5px solid #D3D1D1;
    border-radius: 5px;
    @include font (500, start, Montserrat-Medium, 0.875rem, 1.0625rem);
  }

}

.error_message {
  margin-top: 10px;
  color: red;
  @include font (600, start, Montserrat-SemiBold, 1rem, 1.25rem);
}

.cancel_button {
  position: absolute;
  top: 21px;
  right: 24px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.button_wrapper {
  // margin-top: 2.375rem;
  width: 100%;
  max-width: 326px;
  margin: 2.375rem auto 0;

  &>button {
    margin: 0 auto;
  }
}

@media screen and (max-width: 500px) {
  .leader_modal_window_header {
    padding: 10px;
  }

  .cancel_button {
    width: 10px;
    height: 10px;
    top: 12px;
    right: 10px;
  }

  .leader_modal_window_body {
    padding: 10px;
  }

  .input_text_wrapper {
    margin-top: 10px;
  }

  //.choice_wrapper {
  //  margin-top: 10px;
  //}
}