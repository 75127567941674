@import './../../../../../assets/styles/helpers';
@import './../../../../../assets/styles/mixins';

.item {
  width: 100%;
  display: flex;
  padding: 7px;
  border: .5px solid #f5f1f1;
  box-shadow: 0px 1px  2px rgba(0, 0, 0, 0.25);

  //&:hover {
  //  background-color: #0061ff33;
  //}
}

.item_image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: -2px 4px 15px 0 rgba(30, 32, 24, 0.2);
  background-color: $white;

  img {
    display: block;
    width: 38px;
    height: 38px;
  }

}

.item_name {
  display: flex;
  align-items: center;
  margin-left: 1.875rem;
  @include font (600, start, Montserrat-SemiBold, 1rem, 1.5625rem);

  //&:hover {
  //  background-color:  #0061ff33;
  //}
}

